import React, {useContext} from 'react';
import Stories from './Stories';
import { AuthContext } from '../contexts/AuthContext';
import MintModal from './MintModal';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Tooltip } from 'react-tooltip'

export default function({signatureRefused, unsigned, verifyUser, verificationError, connectWallet, loading}) {

  const {firebaseUser, web3User} = useContext(AuthContext);  

  return (
    <div className="relative">
      { loading &&
      <div className="bg-black opacity-90 absolute h-full w-full z-20 top-0">
        <div className="spinner-container">
          <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          {unsigned && <p className="text-white center opacity-40">Waiting for signature, check your wallet in a few seconds.</p>}
        </div>
      </div>
      }
      <div className="hero-container text-center bg-black mb-8">
        <div className="container py-12 md:py-4 lg:py-16">
          <div className="hero-content text-white md:px-20 px-6 md:w-3/4 w-full">
              <h1 className="md:text-5xl text-4xl font-bold text-center md:pt-32 pt-20 opacity-90 uppercase">Welcome to Blockhead's Studio {loading}</h1>
              <div className="py-4 px-2 md:px-2">
                <p className="md:text-l text-l pt-8 opacity-80">
                  <span>Holders of a </span>
                    <a target="_blank" href="https://goodgriefs.market/"><span className="underline text-highlight">Good Griefs NFT</span></a> 
                    <span> can read for free.  Other story passes can be minted individually.</span></p>
                {
                  web3User == null ? (<div><p className=""></p>
                  <button className="btn md:hidden mt-6" onClick={evt => connectWallet(false)}>
                  Connect Wallet
                </button><div className="md:hidden"><InfoIcon className="cursor-pointer mt-4" data-tooltip-id="mobile-tooltip"
                data-tooltip-html="<p>Please use metamask mobile</p>"
                /></div></div>
                  ) : (
                    (web3User && !firebaseUser) && (
                        <div>
                          <p className="md:text-l text-l pt-4 opacity-80 text-red-400"><ErrorOutlineIcon/> Please finish the authentication process by <a onClick={(event) => {verifyUser(web3User)}}><span className="underline text-highlight cursor-pointer">verifying your account.</span></a></p>
                          {verificationError && <p className="md:text-xl text-l text-red-500 pt-2 opacity-80">Verification failed, please make sure you are on the right account.</p>}
                          {/* <div className="flex items-start mt-6">
                            <MintModal/>
                          </div> */}
                      </div>
                    )
                  )
                }
            </div>
          </div>
        </div>
      </div>
      <Stories/>
      <Tooltip id="mobile-tooltip"/>
      {/* <p data-tip="For ensured compatibility Metamask Browser is recommended">Tooltip</p>
      <ReactTooltip /> */}
    </div>
  )
}
